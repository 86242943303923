<template>
  <section>
      <div class="content-header">
        <h2>Writing for wellness</h2>
      </div>
      <div class="content-wrapper">
        <p>Looking for an activity that’s both therapeutic and enlightening? Journaling can be a great way to take some time out for yourself and to get to know yourself better. But you may not always know where to start. </p>
        <p>The <strong class="bold">Writing for Wellness</strong> toolkit offers writing prompts on different themes, including cultivating optimism, living in the present moment and finding coping strategies to name a few.</p>
        <p>The toolkit is available in a <a :href="`${publicPath}docs/personal/Writing for Wellness Faculty Toolkit.pdf`" download>PDF document</a> or through a series of PowerPoint presentations (used with permission from Diana Brecher):</p>
        <ul>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/1. Strengthening Relationships.pptx`" download>1. Strengthening Relationships</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/2. Gratitude.pptx`" download>2. Gratitude</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/3. Optimism.pptx`" download>3. Optimism</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/4. Living in the Present Moment.pptx`" download>4. Living in the Present Moment</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/5. Coping Strategies.pptx`" download>5. Coping Strategies</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/6. Growth Mindset.pptx`" download>6. Growth Mindset</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/7. Flow.pptx`" download>7. Flow</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/8. Learning to Forgive.pptx`" download>8. Learning to Forgive</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/9. Committing to your goals.pptx`" download>9. Committing to your goals</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/10. Vitality in Action .pptx`" download>10. Vitality in Action</a>  </li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/11. Meditation.pptx`" download>11. Meditation</a></li>
          <li><a :href="`${publicPath}docs/personal/Personal Resilience - Writing for Wellness PP -2021-09-20/12. Savouring Life's Joys.pptx`" download>12. Savouring Life's Joys</a></li>
        </ul>
        <p>These materials are based on the <a href="https://www.ryerson.ca/content/dam/thriveru/resources/ThriveRU-WorkBook-06062018-Web.pdf" target="_blank">Four Seasons Weekly Workbook</a> themes.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '08',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
